<template>
    <div class="home-main">
        <NaviBar />
        <div class="home">
            <div class="container-one">
                <div class="banner-container">
                    <Skeleton v-if="mainImgIsLoading" />
                    <img v-show="!mainImgIsLoading" class="banner-container-img" src="../assets/banners/main_banner_2_low-min.png" @load="onImageLoad()"
                        alt="">
                    <div v-if="!mainImgIsLoading" class="heading-main-wrapper" id="m-head-pos">
                        <div class="logo-icon">
                            <img src="../assets/logo/logo-icon.png" alt="" class="logo-icon-img">
                        </div>
                        <h1 class="heading-main-banner" id="main-banner-heading">Simplified Digital Presence</h1>
                    </div>
                    <div class="tech">
                        <TechImgsSlider v-if="!mainImgIsLoading" />
                    </div>
                </div>
            </div>
            <div class="container-two">
                <div class="heading-sub-wrapper">
                    <h2 class="heading-sub">We provide everything from expert advice to dedicated staffing, taking your
                        business from idea to implementation.</h2>
                </div>
                <h4>All while automating and growing your business at the pace you want.</h4><br>
                <div class="card-wrapper">
                    <div class="card">
                        <div>
                            <p class="title-dev">Product Engineering</p>
                            <p class="title-sub">Develop your SaaS product with us or hire your 1st developer.</p>
                            <p class="content-small">We provide flexible teams, advice, and support to take you from
                                launching your SaaS project from start to finish. Grow from 1 to 100 at your own pace
                            </p>
                        </div>
                        <div>
                            <div class="img-container">
                                <img class="img-container-img" src="../assets/3d/Product-Engineering.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="plus-container">
                        <img src="../assets/icons/plus.png" alt="">
                    </div>
                    <div class="card">
                        <p class="title-dm">Digital Marketing</p>
                        <p class="title-sub">Go to Market with our Digital team, or hire your 1st dedicated Designer.
                        </p>
                        <p class="content-small">We provides consulting and qualified marketing professionals who have
                            managed ad spends with solid performance benchmarks. Get together and plan your digital
                            marketing journey today.</p>
                        <div class="img-container">
                            <img class="img-container-img" src="../assets/3d/digital-marketing-1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-three">
                <ServicesTbl />
            </div>
            <div class="container-three">
                <h1 class="heading-main">BUILD A TEAM <br> OR <br> BE A PART OF OURS</h1><br>
                <div class="heading-sub-wrapper">
                    <h2 class="heading-sub">Our innovative concepts grab the eyes of a seeker and delivers exceptional
                        higher rates of changes to produce top-notch experience for consumer of a respective product.
                    </h2>
                </div>
                <div class="img-container">
                    <img src="../assets/images/home/build_a_team.png" alt="">
                </div>
                <div class="mobile-img-container">
                    <img src="../assets/images/home/build_a_team_mobile.png" alt="">
                </div>
            </div>
            <div class="container-four">
                <div style="width: 100%; position:absolute; top: 30px">
                    <h1 class="heading-main">What makes it work</h1>
                </div>
                <div
                    style="width: 100%; height: 400px; position: absolute; display: flex; justify-content: center; top: 100px;">
                    <div class="img-container">
                        <img src="../assets/images/home/puzzle.png" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg col-md left">
                        <div class="content-wrapper">
                            <div class="content">
                                <span class="title-dev">Development</span><br><br>
                                <p class="fw-bold">We will create a fully tailored approach and unleash your company's
                                    true potential with applications pledge to give our consumers the best experience,
                                    and help you achieve optimum profitability on your IT expenses.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg col-md right">
                        <div class="content-wrapper">
                            <div class="content">
                                <span class="title-dm">Digital Marketing</span><br><br>
                                <p class="fw-bold">Maintain an online presence and reputation, creating quality
                                    digital marketing strategies are more advanced and versatilethan than in the past,
                                    which
                                    requires the initiation and maintenance of professional expertise. That's exactly
                                    what
                                    we got.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="container-four-mob">
                <h1 class="heading-main">What makes it work</h1><br>
                <div class="card-wrapper">
                    <div class="mob-crd mob-crd-bg-dark">
                        <div>
                            <p class="title" style="text-align: left;">Development</p>
                            <p class="content-small" style="text-align: left;">We will create a fully tailored approach
                                and unleash your company's
                                true potential with applications pledge to give our consumers the best experience,
                                and help you achieve optimum profitability on your IT expenses.
                            </p>
                        </div>
                    </div>
                    <div class="plus-container">
                        <img src="../assets/icons/plus.png" alt="">
                    </div>
                    <div class="mob-crd">
                        <div>
                            <p class="title" style="text-align: right;">Digital Marketing</p>
                            <p class="content-small" style="text-align: right;">Maintain an online presence and
                                reputation, creating quality
                                digital marketing strategies are more advanced and versatilethan than in the past, which
                                requires the initiation and maintenance of professional expertise. That's exactly what
                                we got.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container-five">
                <div class="heading-sub-wrapper">
                    <h2 class="heading-sub">Don't take our word for it</h2>
                </div>
                <div>
                    <div class="heading-sub-wrapper">
                        <p class="content">We work with the best in the business marketers, developers, and strategists
                            helping achieve client goals.</p>
                    </div>
                    <div class="img-wrapper">
                        <WorkImgsSlider />
                    </div>
                </div>
            </div>
            <div class="container-six">
                <p class="title-main">Extra services we provide.</p><br><br>
                <div class="row">
                    <div class="col-lg">
                        <div class="card-wrapper">
                            <div class="img-container">
                                <img src="../assets/icons/cyber_security.png" alt="">
                            </div>
                            <p class="container-six-title">Cyber Security</p>
                            <p class="container-six-content">Having that extra step in the right direction in the
                                technology industry would help you much in the digital space. Our experts are always
                                available to give your business the best practice of protecting systems, networks, and
                                programs from digital attacks.</p>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card-wrapper">
                            <div class="img-container">
                                <img src="../assets/icons/content-writing.png" alt="">
                            </div>
                            <p class="container-six-title">SEO Content Writing</p>
                            <p class="container-six-content">We work with your company to implement a comprehensive SEO
                                strategy, driving your website with targeted traffic. The first two pages of Google
                                search results will help your business get measurable results which will drive your
                                company forward.</p>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="card-wrapper">
                            <div class="img-container">
                                <img src="../assets/icons/contact-us-new.png" alt="">
                            </div>
                            <p class="container-six-title">Customer Service</p>
                            <p class="container-six-content">Customer Service Representative would handle complaints,
                                provide appropriate solutions for customers, and follow up to resolve any issues and
                                enhance customers experience of the business. <br> Hire as you get bigger.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-seven">
                <HowWeCanHelp />
            </div>
        </div>
</div>
</template>

<script>

/* eslint-disable */
import { useHead } from '@vueuse/head';
import NaviBar from '../components/navigation_com.vue'
import SheduleCallBtn from '../components/SheduleCallBtn.vue';
import EmailBtn from '../components/EmailBtn.vue';
import HowWeCanHelp from '../components/HowWeCanHelp.vue';
import WorkImgsSlider from '../components/WorkImgsSlider.vue';
import TechImgsSlider from '../components/TechImgsSlider.vue';
import Skeleton from '../components/skeleton-loading-com.vue';
import ServicesTbl from '../components/home-services-redirect-table-com.vue'

export default {
    data() {
        return {
            mainImgIsLoading: true,
        }
    },

    components: { SheduleCallBtn, EmailBtn, HowWeCanHelp, WorkImgsSlider, TechImgsSlider, NaviBar, Skeleton, ServicesTbl },

    mounted() {
        this.setMetaData();
        window.fbq('track', 'ViewContent');
    },

    methods: {
        setMetaData() {
            useHead({
                title: `HOME | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Octick offers comprehensive digital marketing and development solutions tailored to grow your business. From expert advice and staffing to custom web design, SEO services, and eCommerce management, we provide the tools and support to take your business from idea to implementation. Schedule a call today to start your digital transformation.'
                    },
                ],
            });
        },

        onImageLoad() {
            this.mainImgIsLoading = false;
        },
    }
}
</script>

<style scoped>
img {
    max-width: 100%;
    max-height: 100%;
}

#m-head-pos {
    position: relative;
    top: 15%;
}

.row {
    padding: 0;
    margin: 0;
}

h4 {
    text-align: center;
}

.heading-main-banner {
    text-align: center;
    font-size: 55px;
}

#main-banner-heading {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-delay: .5s;
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.heading-sub {
    max-width: 1000px;
    text-align: center;
}

.home {
    min-height: 100vh;
}

.banner-container {
    width: 100%;
    height: 100vh;
    position: relative;
}

.banner-container-img {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
    opacity: .4;
}

.heading-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo-icon {
    width: 200px;
    height: 200px;
}

.logo-icon-img {
    max-width: 100%;
    max-height: 100%;
}

.tech {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 10%;
}

.tech-img-container {
    width: 140px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-two {
    padding: 0 50px;
}

.heading-sub-wrapper {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.card-wrapper {
    display: flex;
    justify-content: space-between;
}

.card-wrapper .card {
    width: 45%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.068);
    border: none;
    transition: all .3s ease;
}

.mob-crd {
    width: 45%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.068);
    border: none;
}

.mob-crd-bg-dark {
    background-color: rgba(0, 0, 0, 0.212);
}

.title-dev {
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(to right, #4527FE, #4527FE, #990ABD, #990ABD);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.title-dm {
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(to right, #246FFF, #246FFF, #03AD5B, #03AD5B);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.title-sub {
    font-size: 22px;
    font-weight: 500;
}

.card-wrapper .card .content-small {
    font-size: 13px;
    font-weight: 500;
}

.plus-container {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-two .img-container {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-two .img-container .img-container-img {
    max-width: 80%;
    max-height: 80%;
    transition: all .3s ease;
}

.card-wrapper .card:hover .img-container-img {
    max-width: 100%;
    max-height: 100%;
}

.container-three {
    padding: 100px 50px 30px 50px;
}

.container-three .img-container {
    width: 100%;
}

.container-three .mobile-img-container {
    width: 100%;
    display: none;
}

.container-four {
    position: relative;
}

.container-four .img-container {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-four .left {
    padding: 300px 0 30px 50px;
    background-color: rgba(0, 0, 0, 0.116);
}

.container-four .right {
    padding: 300px 50px 30px 0;
    text-align: right;
}

.content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container-four .right .content-wrapper {
    align-items: flex-end;
}

.content {
    max-width: 300px;
}

.container-four-mob {
    display: none;
}

.container-five {
    padding-top: 50px;
}

.container-five .content {
    max-width: 500px;
    text-align: center;
}

.container-five .img-wrapper {
    width: 100%;
    height: 100px;
    margin-bottom: 30px;
    /* background-color: rgba(0, 0, 0, 0.123); */
}

.container-six-pa {
    background-color: blue;
    opacity: .5;
}

.container-six {
    color: white;
    padding: 100px 50px;
    position: relative;
    clip-path: polygon(0% 15%, 100% 0%, 100% 85%, 0% 100%);
    background-image: linear-gradient(to right, red, purple, blue);
    position: relative;
}

.container-six .col-lg {
    margin-bottom: 50px;
}

.extra-services-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.extra-services-bg img {
    width: 100%;
    height: 100%;
}

.container-six .title-main {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    /* color: white; */
}

.container-six .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-six .img-container img {
    width: 100px;
    height: 100px;
}

.container-six-title {
    font-size: 20px;
    font-weight: 500;
}

.container-six-content {
    text-align: center;
}

.container-seven {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 1200px) {

    /* #m-head-pos {
        top: 40%;
    } */

    /* .banner-container {
        height: 60vw;
    } */

    /* .tech {
        bottom: 100px;
    } */

    .container-two {
        padding: 0 30px;
    }

    .container-three {
        padding: 80px 30px 20px 30px;
    }

    .container-four .img-container {
        width: 300px;
        height: 300px;
    }

    .container-six {
        padding: 100px 30px;
    }
}

@media only screen and (max-width: 991px) {
    .heading-main, .heading-main-banner {
        font-size: 40px;
    }

    /* .banner-container {
        height: 70vh;
    } */

    .heading-sub {
        font-size: 28px;
    }

    /* .tech {
        bottom: 70px;
    } */

    .card-wrapper .card {
        width: 50%;
        padding: 20px 10px;
    }

    .container-four {
        display: none;
    }

    .container-four-mob {
        display: block;
    }

    .container-six {
        clip-path: polygon(0% 10%, 100% 0%, 100% 90%, 0% 100%);
    }
}

@media only screen and (max-width: 767px) {

    /* #m-head-pos {
        top: 32%;
    } */

    .logo-icon {
        width: 150px;
        height: 150px;
    }

    .banner-container {
        height: 70vh;
    }

    /* .tech {
        bottom: 50px;
    } */

    .container-two,
    .container-four {
        padding: 0 10px;
    }

    .container-three {
        padding: 50px 10px 20px 10px;
    }

    .heading-main, .heading-main-banner {
        font-size: 30px;
    }

    .heading-sub {
        font-size: 22px;
    }

    h4 {
        font-size: 17px;
    }

    .card-wrapper {
        flex-direction: column;
    }

    .card-wrapper .card {
        width: 100%;
        padding: 20px 10px;
    }

    .mob-crd {
        width: 100%;
        padding: 20px 10px;
    }

    .plus-container {
        height: 50px;
        width: 100%;
    }

    .container-three .img-container {
        display: none;
    }

    .container-three .mobile-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-four {
        display: none;
    }

    .container-six {
        padding: 150px 10px;
        clip-path: polygon(0% 5%, 100% 0%, 100% 95%, 0% 100%);
    }
}

@media only screen and (max-width: 510px) {}

@media only screen and (max-width: 385px) {

    .logo-icon {
        width: 100px;
        height: 100px;
        margin-top: 30px;
    }


    .heading-main, .heading-main-banner {
        font-size: 20px;
    }
}

.text-black-head {
    text-align: center;
    font-size: 55px;
    color: black;
}
</style>