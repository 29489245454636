<template>
    <div class="schedule-call">
        <router-link to="/contact-us"><button>Email Us</button></router-link>
    </div>
</template>

<script>

export default {

}
</script>

<style scoped>
button {
	position: relative;
	padding: 10px 20px;
    border-radius: 20px;
    border: none;
    transition: padding .2s;
}

button:before, button:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
    border-radius: 23px;
	z-index: -1;
	animation: steam 10s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 400% 0;
	}
}

</style>