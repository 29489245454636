/* eslint-disable */
import { createWebHistory, createRouter } from 'vue-router'
import Home from './pages/home_scr.vue'
import Development from './pages/development_scr.vue'
import DigitalMarketing from './pages/digital_marketing_scr.vue'
import Solutions from './pages/solutions_scr.vue'
import Affiliates from './pages/affiliates_scr.vue'
import Careers from './pages/careers_scr.vue'
import Contact from './pages/contact_scr.vue'
import Clients from './pages/clients_scr.vue'
import ProCake from './pages/clients_projects/cake_web.vue'
import ProRamada from './pages/clients_projects/ramada_mob.vue'
import ProZavvers from './pages/clients_projects/zavvers_web.vue'
import ProFlylanka from './pages/clients_projects/flylanka_web.vue'
import ProCustomSneakers from './pages/clients_projects/custom-sneakers-web.vue'
import ProYathraTours from './pages/clients_projects/yathra-tours-web.vue'
import ProInventorySystem from './pages/clients_projects/inventory-system-web.vue'
import BlogHome from './pages/blog/blog_home.vue'
import Blog_1 from './pages/blog/blog-1.vue'
import Blog_2 from './pages/blog/blog-2.vue'
import Blog_3 from './pages/blog/blog-3.vue'
import Blog_4 from './pages/blog/blog-4.vue'
import Blog_5 from './pages/blog/blog-5.vue'
import Blog_6 from './pages/blog/blog-6.vue'
import Blog_7 from './pages/blog/blog-7.vue'
import Blog_8 from './pages/blog/blog-8.vue'
import Blog_9 from './pages/blog/blog-9.vue'
import Blog_10 from './pages/blog/blog-10.vue'
import Blog_11 from './pages/blog/blog-11.vue'
// import TnC from './pages/terms&con-scr.vue'
// import PrivacyPolicy from './pages/privacy-policy-scr.vue'
import Test from './pages/test-scr.vue'
import PageNotFound from './pages/page-not-found.vue'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
            title: 'OCTICK - Simplified digital presence',
        },
    },

    {
        name: 'Development',
        path: '/development',
        component: Development,
        meta: {
            title: 'Simplified Development - OCTICK'
        }
    },

    {
        name: 'DigitalMarketing',
        path: '/digital-marketing',
        component: DigitalMarketing,
        meta: {
            title: 'Simplified Marketing - OCTICK'
        }
    },

    {
        name: 'Solutions',
        path: '/solutions',
        component: Solutions,
        meta: {
            title: 'Simplified Hiring - OCTICK'
        }
    },

    {
        name: 'Affiliates',
        path: '/affiliates',
        component: Affiliates,
        meta: {
            title: 'Affiliates - OCTICK'
        }
    },

    {
        name: 'Careers',
        path: '/careers',
        component: Careers,
        meta: {
            title: 'Careers - OCTICK'
        }
    },

    {
        name: 'Contact',
        path: '/contact-us',
        component: Contact,
        meta: {
            title: 'Contact us - OCTICK'
        }
    },

    {
        name: 'Clients',
        path: '/clients',
        component: Clients,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'Blog',
        path: '/blog',
        component: BlogHome,
        meta: {
            title: 'Blog - OCTICK'
        }
    },

    {
        name: 'Blog_1',
        path: '/blog/introducing-octick',
        component: Blog_1,
        meta: {
            title: 'Blog - Introducing OCTICK'
        }
    },

    {
        name: 'Blog_2',
        path: '/blog/mastering-seo-trends-2023',
        component: Blog_2,
        meta: {
            title: 'Blog - Mastering SEO Trends 2023'
        }
    },

    {
        name: 'Blog_3',
        path: '/blog/creating-a-Winning-content-marketing-strategy',
        component: Blog_3,
        meta: {
            title: 'Blog - Creating a Winning Content Marketing Strategy'
        }
    },

    {
        name: 'Blog_4',
        path: '/blog/Leveraging-Web-Development-to-Propel-Your-Business-Growth',
        component: Blog_4,
        meta: {
            title: 'Blog - Leveraging Web Development to Propel Your Business Growth'
        }
    },

    {
        name: 'Blog_5',
        path: '/blog/The-Rise-of-Video-Content-in-Digital-Marketing',
        component: Blog_5,
        meta: {
            title: 'Blog - The Rise of Video Content in Digital Marketing'
        }
    },

    {
        name: 'Blog_6',
        path: '/blog/Green-Marketing-Strategies',
        component: Blog_6,
        meta: {
            title: 'Blog - Green Marketing Strategies'
        }
    },

    {
        name: 'Blog_7',
        path: '/blog/Top-UI/UX-Trends-Shaping-the-Digital-Landscape',
        component: Blog_7,
        meta: {
            title: 'Blog - Top UI/UX Trends Shaping the Digital Landscape'
        }
    },

    {
        name: 'Blog_8',
        path: '/blog/Why-You-Need-Digital-Marketing-as-a-Partner-in-Your-Business',
        component: Blog_8,
        meta: {
            title: 'Blog - Why You Need Digital Marketing as a Partner in Your Business'
        }
    },

    {
        name: 'Blog_9',
        path: '/blog/PPC-for-Small-Businesses-in-2024',
        component: Blog_9,
        meta: {
            title: 'Blog - Why You Need Digital Marketing as a Partner in Your Business PPC for Small Businesses in 2024: Maximizing Your Advertising ROI'
        }
    },

    {
        name: 'Blog_10',
        path: '/blog/Email-marketing-for-startup-business',
        component: Blog_10,
        meta: {
            title: 'Blog - How Email Marketing Can Help Your Startup Business: A Comprehensive Guide'
        }
    },

    {
        name: 'Blog_11',
        path: '/blog/Top-Benefits-of-Choosing-WordPress-for-YourWebsite',
        component: Blog_11,
        meta: {
            title: 'Blog - Top Benefits of Choosing WordPress for YourWebsite'
        }
    },

    {
        name: 'ProCake',
        path: '/clients/cake-web',
        component: ProCake,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'ProRamada',
        path: '/clients/ramada-mob',
        component: ProRamada,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'ProZavvers',
        path: '/clients/zavvers-web',
        component: ProZavvers,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'ProFlylanka',
        path: '/clients/flylanka-web',
        component: ProFlylanka,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'CustomSneakers',
        path: '/clients/custom-sneakers-web',
        component: ProCustomSneakers,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'YathraTours',
        path: '/clients/yathratours-web',
        component: ProYathraTours,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'InventorySystem',
        path: '/clients/inventory-system-web',
        component: ProInventorySystem,
        meta: {
            title: 'Clients - OCTICK'
        }
    },

    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: {
            title: '404 - Page Not Found'
        }
    },

    // {
    //     name: 'Terms&Condition',
    //     path: '/terms&condition',
    //     component: TnC,
    //     meta: {
    //         title: 'Terms & Conditions - OCTICK'
    //     }
    // },

    // {
    //     name: 'PrivacyPolicy',
    //     path: '/privacy-policy',
    //     component: PrivacyPolicy,
    //     meta: {
    //         title: 'Privacy Policy - OCTICK'
    //     }
    // },

    // {
    //     name: 'Test',
    //     path: '/test',
    //     component: Test,

    // },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { top: 0 };
      }
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
})

export default router;