<template>
    <div>
        <NaviBar />
        <div class="main">
            <div class="container-1 row">
                <div class="col-lg-6">
                    <div>
                        <p class="date f-col-fade">Feb, 1 2023</p>
                        <h1>Introducing OCTICK - Your One-Stop IT and Digital Marketing Solutions Provider</h1>
                        <p class="tags f-col-fade">Digital Strategies · Digital Marketing · PPC Marketing · Solutions · Meta · Development</p>
                        <p class="by f-col-fade">by <span class="octick f-col-black">OCTICK</span></p>
                    </div>
                </div>
                <div class="img-1 col-lg-6">
                    <img src="../../assets/blog/post-1/1-thumbnail.webp" alt="">
                </div>
            </div>
            <div class="container-2">
                <p>Are you looking for a reliable and efficient IT and digital marketing company to cater to all your
                    business needs? Look no further! Welcome to OCTICK - your partner in digital success. In this blog
                    article, we will delve into who we are, the extensive range of services we offer, and how we can help
                    your business thrive in the digital landscape.
                </p>
                <div class="img-container">
                    <img src="../../assets/blog/post-1/1-img.webp" alt="">
                </div>
                <h3>01. Development Solutions: Building Your Online Presence</h3>
                <p>At OCTICK, we understand the importance of a strong online presence for modern businesses. Our
                    development services are tailored to meet your specific requirements, ensuring that you have a website
                    and web-based systems that resonate with your brand and engage your audience effectively. Our
                    development expertise includes:
                </p>
                <ul>
                    <li>Wix: We specialize in crafting stunning websites using the Wix platform, providing a seamless user experience that showcases your products and services in the best light.</li>
                    <li>WordPress: Our team of skilled developers creates fully customized WordPress websites, offering flexibility, scalability, and easy content management.</li>
                    <li>Custom Website: If you need a unique and tailored online platform, we can create a custom website that aligns perfectly with your business goals.</li>
                    <li>Web-Based Systems: From CRM systems to advanced e-commerce platforms, we develop web-based solutions to streamline your business processes.</li>
                    <li>POS Systems: Enhance your retail operations with our Point of Sale (POS) system development, offering efficient sales and inventory management.</li>
                    <li>Desktop Applications: Need software solutions for your desktop? We have you covered with powerful and intuitive desktop applications.</li>
                    <li>Mobile Applications: With the growing mobile market, our mobile app development services ensure your business stays accessible to customers on the go.</li>
                </ul>
            </div>
            <div class="container-3">
                <h3>02. Digital Marketing: Elevate Your Online Visibility</h3>
                <p>Having a compelling online presence is essential, but it's equally crucial to drive traffic and engage with your target audience effectively. That's where our digital marketing expertise comes into play. We employ proven strategies to boost your online visibility and brand reputation. Our digital marketing services include:</p>
                <ul>
                    <li>Digital Marketing Strategy: We design comprehensive digital marketing strategies tailored to your business objectives, ensuring a solid online roadmap.</li>
                    <li>Social Media Marketing: Engage and interact with your audience through our social media marketing services, which include social media management and strategic paid ad campaigns.</li>
                    <li>PPC-Google Ads: Leverage the power of Google Ads with our PPC campaigns, driving targeted traffic to your website and increasing conversions.</li>
                    <li>SEO (Search Engine Optimization): Our SEO specialists optimize your website to rank higher in search engine results, driving organic traffic and long-term success.</li>
                    <li>Graphic Design: Captivate your audience with stunning visuals created by our expert graphic design team. Our services include brand identity, logo design, flyer design, packaging design, and banner design.</li>
                </ul>
            </div>
            <div class="container-4">
                <h3>03. Solutions: Simplified Hiring and Dedicated Teams</h3>
                <p>At OCTICK, we believe in providing comprehensive solutions to make your business operations smoother. Our Simplified Hiring service allows you to access dedicated teams of professionals who can become an extension of your in-house staff. Our dedicated teams include:</p>
                <ul>
                    <li>Developers: Hire skilled developers to work on your projects and bring your digital ideas to life.</li>
                    <li>Designers: Our talented designers can create visually appealing graphics and user interfaces that align with your brand image.</li>
                    <li>Customer Service: Enhance your customer support and service capabilities by hiring dedicated customer service professionals.</li>
                </ul>
                <p>OCTICK is your go-to IT and digital marketing company, offering a wide array of services to elevate your online presence and help your business thrive in the digital era. Whether you need website development, digital marketing strategies, or dedicated teams for specialized tasks, we've got you covered. Trust OCTICK to take your business to new heights in the digital landscape. Get in touch with us today and let's embark on a journey of success together!</p>
            </div>
        </div>
    </div>
</template>

<script>
import NaviBar from '../../components/navigation_com.vue';
export default {
    components: {
        NaviBar,
    },

    mounted() {
        window.fbq('track', 'ViewContent');
    },
}
</script>

<style scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main {
    min-height: 100vh;
    padding: 100px;
}

.img-1 {
    min-height: 300px;
    position: relative;
}

.img-container{
    margin: 10px 0 30px 0;
    position: relative;
}

.container-1, .container-2, .container-3, .container-4 {
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .main{
        padding: 50px 20px;
    }
}
</style>