<template>
    <div class="blog">
        <Loader v-if="isLoading" />
        <NaviBar />
        <div class="container-2">
            <div class="left">
                <h5>CATEGORIES</h5>
                <hr>
                <div class="categories-container">
                    <button v-for="(category, index) in filterCategories" :key="index" @click="categorySelect(index)"
                        class="btn-filter-categories"
                        :class="category.isActive ? 'btn-filter-categories-active-col' : ''">{{
                            category.name }}</button>
                </div>
                <hr>
                <br>
                <div class="tags-container">
                    <h5>TAGS</h5>
                    <hr>
                    <button v-for="(tag, index) in filterTags" :key="index" @click="tagsSelect(index)"
                        class="btn-filter-tags" :class="tag.isActive === true ? 'btn-filter-active-col' : ''">{{ tag.name
                        }}</button>
                </div>
                <hr>
                <br>
                <div class="resent-posts">
                    <h5>RECENT POSTS</h5>
                    <hr>
                    <div class="recent-post-box">
                        <router-link to="/blog/The-Rise-of-Video-Content-in-Digital-Marketing">The Rise of Video Content in
                            Digital Marketing</router-link><br><br>
                        <router-link to="/blog/Leveraging-Web-Development-to-Propel-Your-Business-Growth">Leveraging Web
                            Development to Propel Your Business Growth</router-link><br><br>
                        <router-link to="/blog/creating-a-Winning-content-marketing-strategy">Creating a Winning Content
                            Marketing Strategy</router-link><br><br>
                        <router-link to="/blog/mastering-seo-trends-2023">Mastering SEO Trends in 2023</router-link><br><br>
                        <router-link to="/blog/introducing-octick">Introducing OCTICK</router-link>
                    </div>
                </div>
            </div>
            <div class="right" ref="postsTop">
                <div v-if="displayPosts.length !== 0" class="row">
                    <div v-for="(post, index) in displayPosts" :key="index" class="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                        <div class="post-container">
                            <div class="img-box">
                                <img :src="require('../../assets/blog/' + post.img)" alt="">
                            </div>
                            <div class="content-box">
                                <h5>{{ post.title }}</h5>
                                <p class="content">{{ post.content }}</p>
                            </div>
                            <p class="date">{{ post.date }}</p>
                            <button @click="readMore(index)" class="btn-read-more">READ MORE</button>
                        </div>
                    </div>
                </div>
                <div v-else class="empty-post">
                    <p>There is no related post</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import NaviBar from '../../components/navigation_com.vue';
import Loader from '../../components/Loader_com.vue'

export default {

    data() {
        return {
            isLoading: false,
            filterCategories: [
                { name: 'All', isActive: true },
                { name: 'Digital Marketing', isActive: false },
                { name: 'Digital Marketing Strategies', isActive: false },
                { name: 'News & Updates', isActive: false },
                { name: 'Social Media Marketing', isActive: false },
                { name: 'SEO', isActive: false },
                { name: 'Web Design', isActive: false },
                { name: 'Development', isActive: false },
                { name: 'Solutions', isActive: false },
            ],
            filterTags: [
                { name: 'Digital Marketing', value: 'digital-marketing', isActive: false },
                { name: 'Development', value: 'development', isActive: false },
                { name: 'Solutions', value: 'solutions', isActive: false },
                { name: 'Digital Strategies', value: 'digital-strategies', isActive: false },
                { name: 'Events', value: 'events', isActive: false },
                { name: 'Influencer Marketing', value: 'influencer-marketing', isActive: false },
                { name: 'News', value: 'news', isActive: false },
                { name: 'AI', value: 'ai', isActive: false },
                { name: 'E-Commerce', value: 'ecommerce', isActive: false },
                { name: 'PPC Marketing', value: 'ppc-marketing', isActive: false },
                { name: 'Meta', value: 'meta', isActive: false },
            ],

            posts: [
                { title: 'Top Benefits of Choosing WordPress for YourWebsite', img: 'post-11/11-thumbnail.webp', content: 'In today’s digital age, selecting the right platform for your website is crucial. Among the plethora of options available, WordPress stands out as a favorite for many businesses, bloggers, and developers. But why is WordPress the preferred choice for so many?.......', date: 'June 28, 2024', link: '/blog/Top-Benefits-of-Choosing-WordPress-for-YourWebsite', category: ['Web Design', 'Development', 'Solutions'], tags: ['development', 'solutions'] },
                { title: 'How Email Marketing Can Help Your Startup Business: A Comprehensive Guide', img: 'post-10/10-thumbnail.webp', content: 'In the fast-paced world of startup businesses, leveraging effective marketing strategies is crucial for success. One of the most powerful tools at your disposal is email marketing. Not only is it cost-effective, but it also offers a high ........', date: 'May 30, 2024', link: '/blog/Email-marketing-for-startup-business', category: ['Digital Marketing', 'Digital Marketing Strategies', 'Solutions'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'PPC for Small Businesses in 2024: Maximizing Your Advertising ROI', img: 'post-9/9-thumbnail.webp', content: 'In the dynamic digital marketplace of 2024, Pay-Per-Click (PPC) advertising remains a powerful tool for small businesses looking to enhance visibility, drive traffic, and accelerate growth. With the right strategies, small enterprises can .........', date: 'April 26, 2024', link: '/blog/PPC-for-Small-Businesses-in-2024', category: ['Digital Marketing', 'Digital Marketing Strategies', 'Solutions'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'Why You Need Digital Marketing as a Partner in Your Business', img: 'post-8/8-thumbnail.webp', content: 'In today\'s digital age, having an online presence is essential for businesses of all sizes and industries. Consumers are increasingly turning to the internet to research products and services, find local businesses, and make purchasing decisions........', date: 'March 28, 2024', link: '/blog/Why-You-Need-Digital-Marketing-as-a-Partner-in-Your-Business', category: ['Digital Marketing', 'Digital Marketing Strategies', 'Solutions'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'Top UI/UX Trends Shaping the Digital Landscape', img: 'post-7/7-thumbnail.webp', content: 'In the ever-evolving world of design, staying ahead of the curve is crucial. As users become more sophisticated and technology pushes boundaries, so too must UI/UX trends adapt. So, what exciting innovations are set to captivate and convert users in 2024?.......', date: 'February 27, 2024', link: '/blog/Top-UI/UX-Trends-Shaping-the-Digital-Landscape', category: ['Web Design', 'Development', 'Solutions'], tags: ['development', 'solutions'] },
                { title: 'Green Marketing Strategies', img: 'post-6/6-thumbnail.webp', content: 'In today\'s eco-conscious world, businesses are increasingly recognizing the importance of green marketing strategies. As the digital landscape continues to evolve, companies must adapt their marketing efforts to align with sustainable practices.......', date: 'January 28, 2024', link: '/blog/Green-Marketing-Strategies', category: ['Digital Marketing', 'Digital Marketing Strategies', 'Solutions'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'The Rise of Video Content in Digital Marketing', img: 'post-5/5-thumbnail.webp', content: 'In the dynamic realm of digital marketing, video content has emerged as a dominant force. This shift is driven by its unparalleled ability to engage audiences, fostering both brand awareness and customer loyalty. Let\'s explore why video content.......', date: 'December 12, 2023', link: '/blog/The-Rise-of-Video-Content-in-Digital-Marketing', category: ['Digital Marketing', 'Digital Marketing Strategies', 'Solutions'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'Leveraging Web Development to Propel Your Business Growth', img: 'post-4/4-thumbnail.webp', content: 'In today\'s digital era, a strong web presence is crucial for any business aiming to grow. This comprehensive guide will explore how effective web development can be a game-changer for your business......', date: 'Novermber 20, 2023', link: '/blog/Leveraging-Web-Development-to-Propel-Your-Business-Growth', category: ['Web Design', 'Development', 'Solutions'], tags: ['development', 'solutions'] },
                { title: 'Creating a Winning Content Marketing Strategy', img: 'post-3/3-thumbnail.webp', content: 'Content marketing has risen to the forefront of the digital marketing realm. More businesses are now recognizing the incredible value of producing quality, relevant content to attract and retain a clearly-defined audience. .....', date: 'October 18, 2023', link: '/blog/creating-a-Winning-content-marketing-strategy', category: ['digital-marketing', 'digital-marketing-strategies'], tags: ['digital-strategies', 'digital-marketing', 'solutions'] },
                { title: 'Mastering SEO Trends in 2023', img: 'post-2/2-thumbnail.webp', content: 'In the fast-paced world of digital marketing, staying up-to-date with the latest SEO trends and algorithm updates is crucial to maintain a competitive edge. Search Engine Optimization (SEO) remains at the forefront of online.....', date: 'September 26, 2023', link: '/blog/mastering-seo-trends-2023', category: ['SEO', 'Web Design', 'Development', 'Solutions'], tags: ['digital-strategies', 'development', 'solutions'] },
                { title: 'Introducing OCTICK', img: 'post-1/1-thumbnail.webp', content: 'Are you looking for a reliable and efficient IT and digital marketing company to cater to all your business needs? Look no further! Welcome to OCTICK - your partner in digital success. In this blog article, we will delve into.....', date: 'February 1, 2023', link: '/blog/introducing-octick', category: ['Digital Marketing', 'Digital Marketing Strategies', 'News & Updates', 'Social Media Marketing', 'SEO', 'Web Design', 'Development', 'Solutions'], tags: ['digital-strategies', 'development', 'digital-marketing', 'ppc-marketing', 'solutions', 'meta'] },
            ],
            displayPosts: [],
            selectedTags: [],
            unselectedTagIndex: null,
        }
    },

    components: {
        NaviBar,
        Loader,
    },

    mounted() {
        this.setMetaData();
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
            this.displayPosts = this.posts;
        }, 500)
        window.fbq('track', 'ViewContent');
    },

    methods: {

        setMetaData() {
            useHead({
                title: `BLOG | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Stay updated with the latest in digital marketing, SEO, web design, and development through the Octick blog. Explore articles on innovative strategies, industry trends, and expert tips to boost your business growth. Discover how to leverage video content, PPC, email marketing, and more. Read now!'
                    },
                ],
            });
        },

        readMore(index) {
            this.$router.push(this.posts[index].link);
        },

        filterCategoryReset() {
            for (let i = 0; i < this.filterCategories.length; i++) {
                this.filterCategories[i].isActive = false;
            }
        },

        filterTagsReset() {
            this.selectedTags = [];
            for (let i = 0; i < this.filterTags.length; i++) {
                this.filterTags[i].isActive = false;
            }
        },

        scrollToPostTop() {
            const postTop = this.$refs.postsTop;
            postTop.scrollIntoView({ behavior: 'smooth' });
        },

        categorySelect(index) {
            this.filterTagsReset();
            this.displayPosts = [];
            this.filterCategoryReset();
            this.filterCategories[index].isActive = true;

            if (this.filterCategories[index].name === 'All') {
                this.displayPosts = this.posts;
            } else {
                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].category.includes(this.filterCategories[index].name)) {
                        if (this.displayPosts.includes(this.posts[i])) {
                            null;
                        } else {
                            this.displayPosts.push(this.posts[i]);
                        }
                    } else {
                        if (this.displayPosts.includes(this.posts[i])) {
                            let notIncludePostIndex = this.displayPosts.indexOf(this.posts[i]);
                            this.displayPosts.splice(notIncludePostIndex, 1);
                        }
                    }
                }
            }
            this.scrollToPostTop();
        },

        tagsSelect(index) {
            this.filterCategoryReset();
            this.filterCategories[0].isActive = true;

            if (this.filterTags[index].isActive !== true) {
                this.selectedTags.push(this.filterTags[index]);
                this.filterTags[index].isActive = true;
            } else {
                this.unselectedTagIndex = this.selectedTags.indexOf(this.filterTags[index]);
                this.selectedTags.splice(this.unselectedTagIndex, 1);
                this.filterTags[index].isActive = false;
            }

            this.displayPosts = [];

            if (this.selectedTags.length !== 0) {
                for (let i = 0; i < this.posts.length; i++) {
                    for (let j = 0; j < this.selectedTags.length; j++) {
                        if (this.posts[i].tags.includes(this.selectedTags[j].value)) {
                            if (this.displayPosts.includes(this.posts[i])) {
                                null
                            } else {
                                this.displayPosts.push(this.posts[i]);
                            }
                        } else {
                            if (this.displayPosts.includes(this.posts[i])) {
                                let notIncludePostId = this.displayPosts.indexOf(this.posts[i]);
                                this.displayPosts.splice(notIncludePostId, 1);
                            }
                            break;
                        }
                    }
                }
            } else {
                this.displayPosts = this.posts;
            }
            this.unselectedTagIndex = null;
            this.scrollToPostTop();
        }
    }
}
</script>

<style scoped>
.container-2 {
    margin-top: 100px;
    padding: 0 50px 50px 50px;
    display: flex;
}

.left {
    width: 300px;
    flex-shrink: 0;
}

.left .categories-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.right {
    width: 100%;
    padding-left: 20px;
}

.empty-post {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-filter-categories {
    margin-bottom: 5px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    transition: all .3s ease;
}

.btn-filter-categories:hover {
    color: rgb(170, 3, 3);
}

.btn-filter-categories-active-col {
    color: red;
}

.btn-filter-tags {
    padding: 8px 20px;
    margin: 0 10px 10px 0;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    transition: all .3s ease;
}

.btn-filter-tags:hover {
    background-color: rgba(255, 0, 0, 0.113);
}

.btn-filter-active-col {
    background-color: rgba(255, 0, 0, 0.287);
}

.recent-post-box {
    margin-bottom: 10px;
}

a {
    text-decoration: none;
    color: inherit;
    outline: none;
    font-size: 14px;
    transition: all .3s ease;
}

a:hover {
    color: red;
}

.post-container {
    width: 100%;
    margin-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.033);
}

.img-box {
    /* height: 200px; */
    margin-bottom: 10px;
    position: relative;
}

.img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-box {
    height: 200px;
    padding: 20px 20px 0 20px;
    overflow: hidden;
}

.content {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.287);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.date {
    padding-left: 20px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.464);
    margin: 0;
}

.btn-read-more {
    width: 100%;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.158);
    background-color: transparent;
    margin-top: 10px;
    padding: 15px 0;
    font-size: 13px;
}

.resent-posts {
    display: block;
}

@media only screen and (max-width: 991px) {

    .container-2 {
        margin-top: 50px;
        padding: 10px;
        flex-direction: column;
    }

    .left {
        width: 100%;
        order: 2;
    }

    .right {
        padding-left: 0;
    }

    .resent-posts {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .container-2 {
        padding: 10px;
        flex-direction: column;
    }

    .left {
        width: 100%;
        order: 2;
    }

    .right {
        padding-left: 0;
    }

    .resent-posts {
        display: none;
    }
}</style>