<template>
    <div class="clientts">
        <NaviBar />
        <Loader v-if="loading" />
        <div class="portfolio container-xxl">
            <div class="left-navi">
                <button :class="btnAll ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setAll()">ALL</button>
                <button :class="btnWeb ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setWeb()">WEB APPS</button>
                <button :class="btnMobile ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setMobile()">MOBILE APPS</button>
                <button :class="btnGraphics ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setGraphic()">GRAPHICS DESIGN</button>
                <button :class="btnLogo ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setLogo()">LOGO DESIGN</button>
                <button :class="btnDigital ? 'left-navi-btn-selected' : 'left-navi-btn'" @click="setDigital()">DIGITAL MARKETING</button>
            </div>
            <div class="body">
                <div class="heading-main-wrapper">
                    <br>
                    <h3 class="heading-mainn">Our Projects</h3><br>
                </div>
                <div class="left-navi-mobile">
                    <select name="" id="" @change="onChange($event)">
                        <option value="all">All</option>
                        <option value="web">Web Apps</option>
                        <option value="mobile">Mobile Apps</option>
                        <option value="graphic">Graphic designs</option>
                        <option value="logo">Logo designs</option>
                        <option value="digital">Digital Marketing</option>
                    </select>
                </div>
                <div class="items-wrapper row gy-5">
                    <div v-for="(item, index) in displayItems" :key="index" class="col-lg-6 col-md-6">
                        <div class="item">
                            <div class="img">
                                <Skeleton v-if="imageIsLoading"/>
                                <img v-show="!imageIsLoading" :src="require('../assets/images/clients' + item.image)" @load="imageLoad()" alt="">
                            </div>
                            <h4>{{ item.name }}</h4>
                            <h6>{{ item.type }}</h6>
                            <router-link v-if="item.readMore" :to="item.link">READ MORE</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import NaviBar from '../components/navigation_com.vue'
import Loader from '../components/Loader_com.vue'
import Skeleton from '../components/skeleton-loading-com.vue'

export default {
    data() {
        return {
            imageIsLoading: true,
            aLink: "",
            loading: false,
            btnAll: false,
            btnWeb: false,
            btnMobile: false,
            btnGraphics: false,
            btnLogo: false,
            btnDigital: false,
            displayItems: [],
            all: [
                { name: 'ZAVVERS Cleaning', image: '/zavvers-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/zavvers-web', readMore: true },
                { name: 'FlyLanka', image: '/flylanka-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/flylanka-web', readMore: true },
                { name: 'Yathra Tours', image: '/yathra-tour-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/yathratours-web', readMore: true },
                { name: 'Inventory Management System', image: '/inventory-system-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/inventory-system-web', readMore: true },
                { name: 'RAMADA Hotel', image: '/ramada_mobile.png', type: 'MOBILE DEVELOPMENT', link: '/clients/ramada-mob', readMore: true },
                { name: 'CAKE', image: '/cake.png', type: 'WEB DEVELOPMENT', link: '/clients/cake-web', readMore: true },
                { name: 'Custom Sneakers', image: '/custom-sneakers-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/custom-sneakers-web', readMore: true },
                { name: 'ZAVVERS Cleaning', image:'/zavvers-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'Custom Sneakers', image:'/custom-sneakers-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'CO-HOST Sri Lanka', image:'/co-host-lanka-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'BLACK CEYLON', image:'/black-ceylon-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'FlyLanka', image:'/fly-lanka-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'TECHNOGRFIX', image:'/technogrfix-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'Lanka Solutions', image:'/lanka-solutions-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'Mascot', image:'/maskote.jpg', type: 'GRAPHIC DESIGN', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/5.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/6.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/5.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/6.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
            ],
            web: [
                { name: 'ZAVVERS Cleaning', image: '/zavvers-new.png', type: 'WEB DEVELOPMENT', link: '/clients/zavvers-web', readMore: true },
                { name: 'FlyLanka', image: '/flylanka-new.png', type: 'WEB DEVELOPMENT', link: '/clients/flylanka-web', readMore: true },
                { name: 'Yathra Tours', image: '/yathra-tour-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/yathratours-web', readMore: true },
                { name: 'Inventory Management System', image: '/inventory-system-thumb.png', type: 'WEB DEVELOPMENT', link: '/clients/inventory-system-web', readMore: true },
                { name: 'CAKE', image: '/cake.png', type: 'WEB DEVELOPMENT', link: '/clients/cake-web', readMore: true },
                { name: 'Custom Sneakers', image: '/custom-sneakers.png', type: 'WEB DEVELOPMENT', link: '/clients/custom-sneakers-web', readMore: true },
            ],
            mobile: [
                { name: 'RAMADA Hotel', image: '/ramada_mobile.png', type: 'MOBILE DEVELOPMENT', link: '/clients/ramada-mob', readMore: true },
            ],
            logo: [
                { name: 'ZAVVERS Cleaning', image:'/zavvers-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'Custom Sneakers', image:'/custom-sneakers-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'CO-HOST Sri Lanka', image:'/co-host-lanka-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'BLACK CEYLON', image:'/black-ceylon-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'FlyLanka', image:'/fly-lanka-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'TECHNOGRFIX', image:'/technogrfix-logo.jpg', type: 'LOGO DESIGN', link: '', readMore: false },
                { name: 'Lanka Solutions', image:'/lanka-solutions-logo.png', type: 'LOGO DESIGN', link: '', readMore: false },
            ],
            graphic: [
                { name: 'Mascot', image:'/maskote.jpg', type: 'GRAPHIC DESIGN', link: '', readMore: false },
            ],
            digital: [
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/5.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_backers/6.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/oakray_hotel/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/1.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/2.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/3.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/4.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/5.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
                { name: 'Social Media Post', image:'/digital_marketing/samples/6.jpg', type: 'DIGITAL MARKETING', link: '', readMore: false },
            ],
        };
    },

    created() {
        this.setMetaData();
        this.setAll();
    },

    mounted() {
        window.fbq('track', 'ViewContent');
    },

    methods: {

        setMetaData() {
            useHead({
                title: `CLIENTS | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Explore Octick\'s diverse portfolio of projects across web development, mobile apps, graphic design, logo design, and digital marketing. Discover how we have helped businesses like ZAVVERS Cleaning, FlyLanka, Yathra Tours, and more achieve their digital goals. Learn more about our innovative solutions and client success stories.'
                    },
                ],
            });
        },

        onChange(event) {
            let val = event.target.value;
            switch(val){
                case 'all':
                    this.setAll();
                    break;
                case 'web':
                    this.setWeb();
                    break;
                case 'mobile':
                    this.setMobile();
                    break;
                case 'graphic':
                    this.setGraphic();
                    break;
                case 'logo':
                    this.setLogo();
                    break;
                case 'digital':
                    this.setDigital();
                    break;
                default:
                    return;
            }
        },

        setAll() {
            this.btnAll = true;
            this.btnWeb = false;
            this.btnMobile = false;
            this.btnGraphics = false;
            this.btnLogo = false;
            this.btnDigital = false;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.all.length; i++) {
                    this.displayItems.push(this.all[i]);
                }
                window.scrollTo(0, 0);
            }, "500");
            this.imageIsLoading = false;
        },

        setWeb() {
            this.btnAll = false;
            this.btnWeb = true;
            this.btnMobile = false;
            this.btnGraphics = false;
            this.btnLogo = false;
            this.btnDigital = false;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.web.length; i++) {
                    this.displayItems.push(this.web[i]);
                }
                window.scrollTo(0, 0);
            }, "500")
        },

        setMobile() {
            this.btnAll = false;
            this.btnWeb = false;
            this.btnMobile = true;
            this.btnGraphics = false;
            this.btnLogo = false;
            this.btnDigital = false;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.mobile.length; i++) {
                    this.displayItems.push(this.mobile[i]);
                }
                window.scrollTo(0, 0);
            }, "500")
        },

        setLogo() {
            this.btnAll = false;
            this.btnWeb = false;
            this.btnMobile = false;
            this.btnGraphics = false;
            this.btnLogo = true;
            this.btnDigital = false;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.logo.length; i++) {
                    this.displayItems.push(this.logo[i]);
                }
                window.scrollTo(0, 0);
            }, "500")
        },

        setGraphic() {
            this.btnAll = false;
            this.btnWeb = false;
            this.btnMobile = false;
            this.btnGraphics = true;
            this.btnLogo = false;
            this.btnDigital = false;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.graphic.length; i++) {
                    this.displayItems.push(this.graphic[i]);
                }
                window.scrollTo(0, 0);
            }, "500")
        },

        setDigital() {
            this.btnAll = false;
            this.btnWeb = false;
            this.btnMobile = false;
            this.btnGraphics = false;
            this.btnLogo = false;
            this.btnDigital = true;

            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.displayItems = [];
                for (let i = 0; i < this.digital.length; i++) {
                    this.displayItems.push(this.digital[i]);
                }
                window.scrollTo(0, 0);
            }, "500")
        },

        imageLoad() {
            this.imageIsLoading = false;
        }
    },

    components: { NaviBar, Loader, Skeleton }
}

</script>

<style scoped>
.colo-lg-6,
.col-md-6,
.row {
    margin: 0;
}

.clientts {
    min-height: 100vh;
}

.left-navi {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    z-index: 1;
}

.left-navi-mobile {
    margin-bottom: 20px;
    padding: 0 10px;
    display: none;
}

.left-navi-mobile select {
    width: 100%;
    height: 40px;
    font-size: 13px;
    border: 2px solid black;
    border-radius: 10px;
    text-align: center;
}

.left-navi-btn {
    padding: 5px 40px;
    border: none;
    font-size: 13px;
    background-color: rgb(241, 241, 241);
    margin: 5px 0;
    transition: .2s;
}

.left-navi-btn:hover{
    background-color: rgb(255, 255, 255);
}

.left-navi-btn-selected{
    padding: 5px 40px;
    border: none;
    font-size: 13px;
    margin: 5px 0;
    transition: .2s;
    background-color: rgb(255, 215, 215);
}

.container-one {
    padding-top: 50px;
}

.heading-mainn {
    text-align: center;
    font-size: 55px;
    font-weight: 500;
    color: black;
    /* background: linear-gradient(to right, red, red, blue, blue);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text; */
}

.heading-sub {
    text-align: center;
    font-weight: 500;
}

.banner-container {
    width: 100%;
    height: 50vw;
    position: relative;
    background-image: url('../assets/banners/main_banner_2.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.container-xxl {
    padding: 0;
}

.body {
    max-width: 850px;
    margin: 0px auto;
    margin-top: 50px;
}

.body h1 {
    color: #365D87;
}

.item {
    margin-bottom: 50px;
}

.item h4 {
    font-size: 35px;
    font-weight: 500;
    background: linear-gradient(to right, purple, purple, blue, blue);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.item .img {
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item a {
    text-decoration: none;
    padding: 5px 20px;
    border: none;
    color: white;
    background: linear-gradient(to right, purple, purple, blue, blue);
}

.sized-box {
    height: 200px;
}


@media only screen and (max-width: 991px) {

    .heading-mainn {
        font-size: 40px;
    }

    .heading-sub {
        font-size: 28px;
    }

    .body {
        margin: 30px 0;
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {

    .left-navi {
        display: none;
    }

    .left-navi-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heading-mainn {
        font-size: 30px;
    }

    .heading-sub {
        font-size: 22px;
    }

    .body {
        margin: 20px 0;
        padding: 10px;
    }

}
</style>