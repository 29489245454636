<template>
    <div>
        <div class="main">
            <!-- lg -->
            <div class="table-lg">
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Dyanamic-Websign.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Dynamic Web <br>Design</p>
                                <a href="/development#custom-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/E-commerce-Management.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">eCommerce <br>Management</p>
                                <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/social-media.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Social Media <br>Marketing</p>
                                <a href="/digital-marketing#social-media-marketing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/SEO.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">SEO <br>Services</p>
                                <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/paid-media-marketing.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Paid Media <br>Marketing</p>
                                <a href="/digital-marketing#paid-social-media-marketing&ppc" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Content-Writing.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Content <br>Writing</p>
                                <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/graphic-design.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Custom <br>Logo Design</p>
                                <a href="/digital-marketing#logo-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Flyer-Brochure-design.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Flyer Brochure <br>Poster Design</p>
                                <a href="/digital-marketing#flyer-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/E-Commerce-website.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">eCommerce <br>Website</p>
                                <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/mobile-development.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Mobile App <br>Development</p>
                                <a href="/development#mobile-applications" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Web-Maintenance.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Website <br>Maintenance</p>
                                <a href="/development#website-maintenance" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Domain-Hosting.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Domain <br>& Hosting</p>
                                <a href="/development#domain&hosting" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- md -->
            <div class="table-md">
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Dyanamic-Websign.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Dynamic Web <br>Design</p>
                                <a href="/development#custom-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/E-commerce-Management.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">eCommerce <br>Management</p>
                                <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/social-media.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Social Media <br>Marketing</p>
                                <a href="/digital-marketing#social-media-marketing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/SEO.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">SEO <br>Services</p>
                                <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/paid-media-marketing.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Paid Media <br>Marketing</p>
                                <a href="/digital-marketing#paid-social-media-marketing&ppc" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Content-Writing.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Content <br>Writing</p>
                                <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/graphic-design.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Custom <br>Logo Design</p>
                                <a href="/digital-marketing#logo-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Flyer-Brochure-design.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Flyer Brochure <br>Poster Design</p>
                                <a href="/digital-marketing#flyer-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/E-Commerce-website.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">eCommerce <br>Website</p>
                                <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/mobile-development.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Mobile App <br>Development</p>
                                <a href="/development#mobile-applications" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dis-fl jus-con-sp-bt">
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Web-Maintenance.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Website <br>Maintenance</p>
                                <a href="/development#website-maintenance" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bor-all">
                        <div class="content-box">
                            <div class="icon-box">
                                <img class="img-icon" src="../assets/home-services/Domain-Hosting.png" alt="">
                            </div>
                            <div class="title-btn-box">
                                <p class="title">Domain <br>& Hosting</p>
                                <a href="/development#domain&hosting" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- sm -->
            <div class="table-sm">
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/Dyanamic-Websign.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Dynamic Web <br>Design</p>
                            <a href="/development#custom-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/E-commerce-Management.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">eCommerce <br>Management</p>
                            <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/social-media.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Social Media <br>Marketing</p>
                            <a href="/digital-marketing#social-media-marketing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/SEO.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">SEO <br>Services</p>
                            <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/paid-media-marketing.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Paid Media <br>Marketing</p>
                            <a href="/digital-marketing#paid-social-media-marketing&ppc" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/Content-Writing.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Content <br>Writing</p>
                            <a href="/development#seo-content-writing" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/graphic-design.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Custom <br>Logo Design</p>
                            <a href="/digital-marketing#logo-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/Flyer-Brochure-design.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Flyer Brochure <br>Poster Design</p>
                            <a href="/digital-marketing#flyer-design" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/E-Commerce-website.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">eCommerce <br>Website</p>
                            <a href="/development#e-commerce-websites" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/mobile-development.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Mobile App <br>Development</p>
                            <a href="/development#mobile-applications" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/Web-Maintenance.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Website <br>Maintenance</p>
                            <a href="/development#website-maintenance" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-purple.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <div class="bor-all">
                    <div class="content-box">
                        <div class="icon-box">
                            <img class="img-icon" src="../assets/home-services/Domain-Hosting.png" alt="">
                        </div>
                        <div class="title-btn-box">
                            <p class="title">Domain <br>& Hosting</p>
                            <a href="/development#domain&hosting" class="link-box dis-fl">
                                    <p class="link pad-mar-no">Learn More</p>
                                    <div class="arrow-box">
                                        <img src="../assets/icons/double-arrow-green.png" alt="">
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.table-lg {
    display: block;
}

.main {
    border: 1px solid rgb(0, 0, 0, 0.105);
}

.dis-fl {
    display: flex;

}

.jus-con-sp-bt {
    justify-content: space-between;
}

.bor-all {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.105);
    padding: 30px;
}

.bor-all:hover .img-icon{
    max-width: 100%;
    max-height: 100%;
}

.content-box {
    display: flex;
}

.icon-box {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-icon {
    max-width: 80%;
    max-height: 80%;
    transition: .3s;
}

.title-btn-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pad-mar-no {
    padding: 0;
    margin: 0;
}

.title {
    font-size: 23px;
    font-weight: bold;
    line-height: 23px;
}

.link-box {
    text-decoration: none;
    align-items: center;
    transition: all .5s ease;
}

.link {
    font-size: 15px;
    color: grey;
    margin-right: 5px;
    transition: all .5s ease;
}

.arrow-box {
    width: 15px;
    height: 15px;
    position: relative;
    left: 0px;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-box img{
    max-width: 100%;
    max-height: 100%;
}

.link-box:hover .arrow-box {
    left: 5px;
}

.link-box:hover .link{
    color: rgb(72, 72, 72);
}

.table-md {
    display: none;
}

.table-sm {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .table-lg {
        display: none;
    }

    .table-md {
        display: block;
    }

    .table-sm {
        display: none;
    }
}

@media only screen and (max-width: 670px) {
    .table-lg {
        display: none;
    }

    .table-md {
        display: none;
    }

    .table-sm {
        display: flex;
        flex-direction: column;
    }
}
</style>