<template>
    <div class="digital-marketing">
        <NaviBar />
        <div class="container-1">
            <MainBanner>
                <template v-slot:header-1>Simplified Marketing.</template>
                <template v-slot:header-2>Our Digital Marketing Value</template>
                <template v-slot:content-2>We're a digital solutions agency creating next-level marketing strategies
                    that maintain an online presence and
                    reputation for your business. We understand that every company has its own unique goals. We must
                    have a
                    well-planned and well-implemented digital marketing strategy that links specific business and market
                    requirements to leverage online resources to achieve those objectives. Our well experienced
                    strategic team will
                    build a game plan for your marketing team. With the help of our creative designers, we will be able
                    to super
                    charge your online presence.
                </template>
                <template v-slot:header-3>Our Features</template>
                <template v-slot:content-3>
                    <ul>
                        <li>Your brand tone is more than an ordinary name. It is a crafted strategy that
                            you need to maintain. We strategize on important improvements based on
                            past performances and industry research.</li>
                        <li>Story telling is our passion. We help you create buzz and widen your social
                            reach through highly interactive content and campaigns.</li>
                        <li>Take advantage of our multi-channel integration strategy for effective data
                            integrations across all your social channels and connect with your target
                            audience efficiently.</li>
                        <li>Drive vast amount of traffic to your content and your website, with our
                            impactful campaigns. If you do not have a high-performance website,
                            contact Octick to build one!</li>
                        <li>We consistently listen to and monitor social conversations around your
                            interests to help you gain better consumer insights and discover potential
                            opportunities.</li>
                        <li>Our reports are customized to help your organization improve on the
                            advertising budgets, by measuring past performances. We use top social
                            media marketing tools to analyze your performance.</li>
                    </ul>
                </template>
            </MainBanner>
        </div>
        <div class="container-2">
            <div class="heading">
                <h3>Our Digital Marketing Process</h3>
            </div>
            <div class="img-container">
                <img src="../assets/images/digital_markrting/Inforgraphs.png" alt="">
            </div>
            <div class="mob-img-container">
                <img src="../assets/images/digital_markrting/Inforgraphs-mobile.png" alt="">
            </div>
        </div>
        <div class="container-3 container-xl">
            <div class="heading">
                <h3>Find your Digital way of living</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Digital marketing</template>
                        <template v-slot:title-sub>Maintain an online presence and reputation, creating a quality
                            customer-oriented content is key.</template>
                        <template v-slot:content>We understand that every company has its own unique goals. And we must
                            have a well-planned and well-implemented digital marketing strategy that links specific
                            business and market requirements to leverage online resources to achieve those objectives.
                            We can help accelerate growth online and increase your digital marketing returns by working
                            in cooperation.</template>
                        <template v-slot:img>
                            <img src="../assets/images/home/tech_logos/google-ads-1.png" alt="">
                            <div style="width: 50px;"></div>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/linkedin-ads.webp" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="social-media-marketing">
                    <Card>
                        <template v-slot:title-main>Social Media marketing</template>
                        <template v-slot:title-sub>Maintain an online presence and reputation, creating a quality
                            customer oriented content is key.</template>
                        <template v-slot:content>brands with the various aspects of their social media marketing
                            strategy by deploying outcome oriented services around social strategy, content production,
                            community management, paid social advertising, influence marketing, etc. We have an
                            experienced team available at your convenience.</template>
                        <template v-slot:img>
                            <img style="width: 70px;" src="../assets/images/home/tech_logos/meta.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="paid-social-media-marketing&ppc">
                    <Card>
                        <template v-slot:title-main>Paid Social Media Marketing & PPC</template>
                        <template v-slot:title-sub>Maximize your online reach and ROI with Paid Social Media Marketing & PPC. Targeted ads to drive results and grow your business.</template>
                        <template v-slot:content>This marketing model prioritizes consent and engagement over screens full
                            of unwanted ads. Instead, we focus on a more targeted and direct way to reach your audience,
                            ensuring that your message resonates with those who are genuinely interested in what you offer.
                            This approach not only increases brand visibility but also creates a more receptive
                            audience.</template>
                        <template v-slot:img>
                            <img src="../assets/images/home/tech_logos/google-ads-1.png" alt="">
                            <div style="width: 50px;"></div>
                            <img style="width: 70px;" src="../assets/images/home/tech_logos/meta.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Graphics and designs</template>
                        <template v-slot:title-sub>Creating graphics/designs catered for your needs can be achived by
                            connecting you with the right designers for your job.</template>
                        <template v-slot:content>It is a matter of making artwork, even though you have the requisite
                            resources and technologies, you cannot produce a picture without real creativity. Our artists
                            are still motivated and willing to produce real art for any demand
                            of the consumer.</template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ps.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="logo-design">
                    <Card>
                        <template v-slot:title-main>Logo Design</template>
                        <template v-slot:title-sub>Collaborating with our logo design experts will get your logo done
                            just the way you want it with unlimited revisions.</template>
                        <template v-slot:content>We know design. Creating a custom logo that makes a lasting impression
                            with a creative branding collective. Our immersive creative brief makes it easier to explain
                            your vision and set up your budget for the ideal logo template. You will be able to
                            collaborate with designers for several days to get your dream logo designed to
                            perfection.</template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Brand identity (brand book)</template>
                        <template v-slot:title-sub>Create a custom brand book for your business can impress and educate
                            your customers.</template>
                        <template v-slot:content>Our production services for Hamilton labels blend creative with
                            conceptual thought. As a result, new goods and services are easily embraced and the
                            corporate picture is strengthened. The core of your marketing is undeniably your brand
                            name.</template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Voice over</template>
                        <template v-slot:title-sub>Finding the right voice for your need is possible with our wide range
                            of voice artists.</template>
                        <template v-slot:content>Voice over services are provided by professional voice artists from
                            their home-built professional studios around the globe more and more frequently. And we
                            decided to forge and bring together the best global voices under one online roof.</template>
                        <template v-slot:img>
                            <img src="" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Product shoots</template>
                        <template v-slot:title-sub>From lifestyle product photography to e-commerce product photography
                            by professionals from the space.</template>
                        <template v-slot:content>Greater photographs are one of the best products for selling and we
                            will provide your competitors with photos. We will ensure that everything will be shot to
                            the best possible standards. To deliver exactly what you need, our creative team will talk
                            with you before and while you're shooting.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/canon.webp" alt="">
                            <div style="width: 50px;"></div>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/sony.webp" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Custom music production</template>
                        <template v-slot:title-sub>Creating the right tune for your commercial or creating a
                            professionally made song catered for your occasion.</template>
                        <template v-slot:content>The instruments, electronic music, orchestra or mix can be used by our
                            producers in any style depending on the sound. Whether it be music for your song, movie or
                            podcast every music is specifically produced for your project.</template>
                        <template v-slot:img>
                            <img style="width: 50px;" src="../assets/images/home/tech_logos/fl.webp" alt="">
                            <div style="width: 50px;"></div>
                            <img style="width: 50px;" src="../assets/images/home/tech_logos/garage-band.webp" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Video editing</template>
                        <template v-slot:title-sub>Got footage of your event and want it edited right? Or starting your
                            YouTube channel and want your videos edited? We got you.</template>
                        <template v-slot:content>We were able to help many creators and businesses down our video
                            editing service. Starting from simply Instagram reels to Tik Tok videos up to YouTube videos,
                            we do it all. For this to work we have the best creative team combined with the most
                            up-to-date video editing software and gear.</template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pr.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="flyer-design">
                    <Card>
                        <template v-slot:title-main>Flyer Design</template>
                        <template v-slot:title-sub>Use marketing flyers to bring in new customers to a business or to
                            announce a
                            new sale or promotion.</template>
                        <template v-slot:content>Are you thinking about how you could promote your business’s ad
                            campaigns? Are you wondering
                            how you could boost the promotion of your business’s ad campaigns? A flyer campaign could be
                            just
                            what you need! <br>
                            Flyers may seem like an old-school way to market, but they still have great value when it
                            comes to
                            advertising.
                        </template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ps.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Business Card Design</template>
                        <template v-slot:title-sub>A business card says a lot about your business, in more ways than
                            one. A good
                            card succinctly conveys what your business is all about.</template>
                        <template v-slot:content>A good business card tells people something about you that isn’t
                            written on the card: It
                            speaks to your level of professionalism and preparedness. It can also show off your taste: A
                            well-designed, eye-catching business card will make you look creative, innovative, and
                            thoughtful. It’s a simple and effective way of standing out from the pack.
                        </template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Packaging Design</template>
                        <template v-slot:title-sub>First impressions are very important, and your packaging is often a
                            consumer’s
                            first introduction to the product.</template>
                        <template v-slot:content>When considering the importance of product packaging, it’s crucial to
                            consider the wants and
                            needs of the consumer. The main goal of creating any product, after all, is to attract
                            customers and encourage them to buy your product. Because first impressions are so
                            important to the buying process, well-designed product packaging can go a long way in
                            putting your product into the hands of buyers.
                        </template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Banner Design</template>
                        <template v-slot:title-sub>For any type of event that will benefit from the maximum amount of
                            exposure
                            possible, suspending a banner over the street is a great way to advertise.</template>
                        <template v-slot:content>Banners are used primarily for an advertising purpose to publicize the
                            advertiser’s products
                            or services. Its most common use is for the promotion of brands, events, products or
                            websites.
                        </template>
                        <template v-slot:img>
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ps.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/pen.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/ai-logo.png" alt="">
                        </template>
                    </Card>
                </div>
            </div>
            <!-- sub tiles -->
            <div class="h-line"></div>
            <div class="row">
                
            </div>
        </div>
        <div class="container-4">
            <HowWeCanHelp />
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import NaviBar from '../components/navigation_com.vue'
import MainBanner from '../components/main-banner-dm.vue';
import HowWeCanHelp from '../components/HowWeCanHelp.vue';
import Card from '../components/card-dm.vue';

export default {
    components: { MainBanner, HowWeCanHelp, NaviBar, Card },

    mounted() {
        this.setMetaData();
        window.fbq('track', 'ViewContent');
    },

    methods: {
        setMetaData() {
            useHead({
                title: `DIGITAL MARKETING | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Octick offers next-level digital marketing strategies to enhance your online presence and reputation. Our services include social media marketing, PPC, graphics and design, logo creation, brand identity, and more. Let our experienced team craft a tailored marketing plan to drive traffic and boost your business growth. Schedule a call today to start your digital marketing journey.'
                    },
                ],
            });
        },
    }
}

</script>

<style scoped>
img {
    max-width: 100%;
}

.container-2 {
    padding: 0 50px;
}

.heading h3 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 50px;
    text-align: center;
    background: linear-gradient(to right, #246FFF, #246FFF, #03AD5B, #03AD5B);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.img-container {
    width: 100%;
    margin: 0 auto;
}

.mob-img-container {
    width: 100%;
    display: none;
}

.container-3 {
    margin-top: 100px;
    padding: 0 50px;
}

.card {
    margin: 10px 0;
    border: none;
}

@media only screen and (max-width: 1200px) {

    .container-2 {
        padding: 0 30px;
    }

    .container-3 {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 991px) {

    .heading h3 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 767px) {

    .colo-lg-6,
    .col-md-6,
    .row {
        padding: 0;
        margin: 0;
    }

    .img-container {
        display: none;
    }

    .mob-img-container {
        display: block;
    }

    .container-2 {
        padding: 0 10px;
    }

    .heading h3 {
        font-size: 20px;
    }

    .container-3 {
        margin-top: 30px;
        padding: 0 10px;
    }
}</style>