<template>
    <div>
        <transition name="navi-trans">
            <div v-if="showNav" class="navi">
                <div class="navigation light-theme">
                    <div class="navigation-wrapper">
                        <div class="logo">
                            <router-link to="/">
                                <img src="../assets/logo/Octic_Logo_Black_landscape.png" alt="">
                            </router-link>
                        </div>
                        <div class="navi-links">
                            <router-link to="/development">Development</router-link>
                            <router-link to="/digital-marketing">Digital Marketing</router-link>
                            <router-link to="/solutions">Solutions</router-link>
                            <router-link to="/clients">Clients</router-link>
                            <SheduleCallBtn />
                        </div>
                        <div class="burger-btn">
                            <button @click="mobileNaviIsVisible = true;">
                                <div class="burger"></div>
                                <div class="burger"></div>
                                <div class="burger"></div>
                            </button>
                        </div>
                    </div>
                </div>
                <transition name="mobile-navigation-wrapper-trans">
                    <div v-if="mobileNaviIsVisible" class="mobile-navigation-wrapper" v-click-outside="naviClickOutside">
                        <div class="top">
                            <a href="/">
                                <img :src="themeStatus ? require('../assets/logo/Octic_Logo_White_landscape.png') : require('../assets/logo/Octic_Logo_Black_landscape.png')"
                                    alt="">
                            </a>
                            <button @click="mobileNaviIsVisible = false;" class="btn-close"><img
                                    src="../assets/icons/close.png" alt=""></button>
                        </div>
                        <div class="bottom">
                            <button @click="mobileNaviIsVisible = false;" class="btn-mobile-nav-link">
                                <router-link to="/development">Development</router-link>
                            </button>
                            <button @click="mobileNaviIsVisible = false;" class="btn-mobile-nav-link">
                                <router-link to="/digital-marketing">Digital Marketing</router-link>
                            </button>
                            <button @click="mobileNaviIsVisible = false;" class="btn-mobile-nav-link">
                                <router-link to="/solutions">Solutions</router-link>
                            </button>
                            <button @click="mobileNaviIsVisible = false;" class="btn-mobile-nav-link">
                                <router-link to="/clients">Clients</router-link>
                            </button>
                            <SheduleCallBtn class="shedule-call-btn"/>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>

    </div>
</template>

<script>
import SheduleCallBtn from '../components/SheduleCallBtn.vue';
import vClickOutside from 'click-outside-vue3';

export default {

    data() {
        return {
            themeStatus: null,
            mobileNaviIsVisible: false,
            naviTrans: false,

            showNav: true,
            lastScrollTop: 0,
            mobNaviItemIsVisible: false,
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: {
        SheduleCallBtn
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {

        handleScroll() {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > this.lastScrollTop) {
                this.showNav = false;
            } else {
                this.showNav = true;
            }
            this.lastScrollTop = st <= 0 ? 0 : st;
        },

        setTheme() {
            if (process.client) {
                if (localStorage.getItem('storedData') === 'false') {
                    localStorage.setItem('storedData', 'true')
                } else {
                    localStorage.setItem('storedData', 'false')
                }
            }
            this.$store.commit('setThemeState');
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },

        naviClickOutside() {
            this.mobileNaviIsVisible = false;
        }
    }

}

</script>

<style scoped>

.shedule-call-btn{
    margin-top: 50px;
}
.btn-mobile-nav-link {
    margin-bottom: 20px;
    border: none;
}

.mobile-navi-trans-enter-active,
.mobile-navi-trans-leave-active {
    transition: all 1s;
}

.mobile-navi-trans-enter-from,
.mobile-navi-trans-leave-to {
    right: -300px;
}

.navi {
    height: 78px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.navigation {
    width: 100%;
    padding: 10px 50px;
    position: fixed;
    transition: .5s;
    z-index: 1000;
}

.navigation-dark {
    width: 100%;
    padding: 10px 50px;
    position: fixed;
    background-color: black;
    color: white;
    transition: .5s;
}

.navigation-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo img {
    width: 200px;
    margin: auto 0;
}

.navi-links {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navi-links-dark {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navi-links a {
    text-decoration: none;
    color: black;
    font-size: 17px;
    transition: .2s;
}

.navi-links-dark a {
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: .2s;
}

.navi-links a:hover {
    color: red;
}

.bottom{
    height: 70vh;
}

.navi-links a.router-link-exact-active,
.mobile-navigation-wrapper .bottom a.router-link-exact-active {
    color: red;
}

.burger-btn {
    display: none;
}

.burger-btn button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.burger {
    width: 30px;
    height: 3px;
    margin: 3px 0;
    background-color: black;
}

.mobile-navigation {
    width: 500px;
    height: 100vh;
    background-color: rgb(238, 238, 238);
    position: fixed;
    right: 0;
    z-index: 2000;
}

.mobile-navigation-wrapper {
    width: 500px;
    height: 100vh;
    padding: 30px;
    background-color: rgb(238, 238, 238);
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 1000;
}

.mobile-navigation-wrapper .top {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.mobile-navigation-wrapper .top img {
    width: 120px;
}

.mobile-navigation-wrapper .top button {
    width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
}

.mobile-navigation-wrapper .top button img {
    max-width: 100%;
    max-height: 100%;
}

.mobile-navigation-wrapper .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-navigation-wrapper .bottom a {
    margin-bottom: 20px;
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 1200px) {
    .navi {
        height: 66px;
    }

    .navigation {
        padding: 10px 30px;
    }

    .navi-links {
        display: none;
    }

    .burger-btn {
        display: block;
    }

    .mobile-navigation {
        display: flex;
        justify-content: right;
    }
}

@media only screen and (max-width: 991px) {
    .navi {
        height: 55px;
    }

    .logo img {
        width: 160px;
    }
}

@media only screen and (max-width: 767px) {
    .navigation {
        padding: 10px 10px;
    }

    .logo img {
        width: 120px;
    }

    .btn-mobile-nav-link a{
        font-size: 20px;
    }
}

@media only screen and (max-width: 530px) {
    .mobile-navigation-wrapper {
        width: 100vw;
    }
}

.navi-trans-enter-from,
.navi-trans-leave-to {
    top: -70px;
}

.navi-trans-enter-active,
.navi-trans-leave-active {
    transition: all .3s ease;
}

.mobile-navigation-wrapper-trans-enter-from,
.mobile-navigation-wrapper-trans-leave-to {
    right: -100%;

}

.mobile-navigation-wrapper-trans-enter-active,
.mobile-navigation-wrapper-trans-leave-active {
    transition: all .5s ease;
}
</style>