<template>
    <div class="schedule-call">
        <Loader v-if="loader" />
        <transition name="schedule">
            <SheduleCall :scheduleVisible="scheduleVisible" v-if="scheduleCallIsVisible" />
        </transition>
        <button @click="scheduleCall()">Schedule a Call</button>
    </div>
</template>

<script>
import SheduleCall from './SheduleCall.vue';
import Loader from './Loader_com.vue';

export default {

    transition: 'schedule',

    data() {
        return {
            scheduleCallIsVisible: false,
            loader: false,
        };
    },
    methods: {
        scheduleVisible(sheduleStatus) {
            this.scheduleCallIsVisible = sheduleStatus;
        },

        scheduleCall() {
            this.loader = true;
            setTimeout(() => {
                this.loader = false;
                this.scheduleCallIsVisible = true;
            }, "500")
        }
    },
    components: { SheduleCall, Loader }
}
</script>

<style scoped>

.schedule-enter-active, .schedule-leave-active { transition: opacity .2s; }
  .schedule-enter, .schedule-leave-active { opacity: 0; }

button {
	position: relative;
	padding: 10px 20px;
    border-radius: 20px;
    border: none;
}

button:before, button:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
    border-radius: 23px;
	z-index: -1;
	animation: steam 10s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

</style>