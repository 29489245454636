<template>
    <div>
        <div class="slider">
            <div class="slide-track">
                <div class="slide">
                <img src="../assets/images/home/tech_logos/pen.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/wix_1.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/node.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/google-ads-1.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/dotnet.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/react.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/xd.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/google_ana.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/java.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/wordpress.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/ps.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/meta-1.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/js.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/vue.png" height="35" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/blender.png" height="35" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/figma.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/flutter.png" height="30" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/shopify.png" height="35" width="125" alt="" />
                </div>
                <!--  -->
                <div class="slide">
                <img src="../assets/images/home/tech_logos/pen.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/wix_1.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/node.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/google-ads-1.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/dotnet.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/react.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/xd.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/google_ana.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/java.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/wordpress.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/ps.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/meta-1.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/js.png" height="35" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/vue.png" height="35" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/blender.png" height="35" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/figma.png" height="30" width="125" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/flutter.png" height="30" width="100" alt="" />
                </div>
                <div class="slide">
                <img src="../assets/images/home/tech_logos/shopify.png" height="35" width="125" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-125px * 18));
  }
}

.slider {
  height: 50px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 120px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(125px * 36);
}

.slider .slide {
  height: 50px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .slider .slide-track {
    animation: scroll 60s linear infinite;
  }
}

</style>