<template>
    <NaviBar />
    <div class=main>
        <h1>404</h1>
        <h4>Page Not Found</h4>
        <router-link to="/">Home</router-link>
    </div>
</template>

<script>
import NaviBar from '../components/navigation_com.vue'

export default {
    components: {
        NaviBar,
    }
}

</script>

<style scoped>

h1{
    font-size: 200px;
    background: linear-gradient(to right, red, red, blue, blue);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

a{
    padding: 7px 20px;
    text-decoration: none;
    color: white;
    background-color: blue;
    transition: .3s;
}

a:hover{
    background-color: red;
}

.main{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    h1{
        font-size: 100px;
    }
}
    
</style>