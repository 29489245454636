<template>
	<NaviBar />
	<div class="contact-us">
		<Loader v-if="loading" />
		<div class="contact-us-container container-xl">
			<p class="heading-mainn">Contact Us</p>
			<div class="row">
				<div class="col-lg-6">
					<div class="right">
						<input v-model="firstName" class="form-control" type="text" name="" id="" placeholder="First Name">
						<input v-model="lastName" class="form-control" type="text" name="" id="" placeholder="Last Name">
						<input v-model="email" class="form-control" type="email" name="" id="" placeholder="E-Mail">
						<input v-model="mobile" class="form-control" type="text" name="" id="" placeholder="Mobile Phone">
						<textarea v-model="message" class="form-control" name="" id="" cols="30" rows="3"
							placeholder="Message"></textarea>
						<div class="btn-form-submit">
							<button @click="submit()">SUBMIT</button>
						</div>
					</div>
				</div>
				<div class="info col-lg-6">
					<div class="left">
						<div class="content-wrapper">
							<div class="icon-box">
								<img src="../assets/icons/location.png" alt="">
							</div>
							<div class="content">
								<h4>ADDRESS:</h4>
								<p class="address-type">Head Office:</p>
								<p>
									316/1/1 Peradeniya Road, <br>
									Kandy 20000 <br>
									Sri Lanka
								</p>
								<p class="address-type">AUS:</p>
								<p>
									47a Powell st, <br>
									Joondanna 6060, <br>
									Perth <br>
									Australia
								</p>
							</div>
						</div>
						<div class="content-wrapper">
							<div class="icon-box">
								<img src="../assets/icons/phone.png" alt="">
							</div>
							<div class="content">
								<h4>PHONE:</h4>
								<a href="tel:+61452447899">
									<span class="cnt-au">AU</span>
									<span>+61 452 447 899</span>
								</a><br>
								<a href="tel:+18483502408">
									<span class="cnt-us">US</span>
									<span>+1 (848) 350 2408</span>
								</a><br>
								<a href="tel:+94771230087">
									<span class="cnt-sl">SL</span>
									<span>+94 77 123 0087</span>
								</a>
							</div>
						</div>
						<div class="content-wrapper">
							<div class="icon-box">
								<img src="../assets/icons/mail.png" alt="">
							</div>
							<div class="content">
								<h4>EMAIL:</h4>
								<a href="mailto:info@octick.com">info@octick.com</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useHead } from '@vueuse/head';
import NaviBar from '../components/navigation_com.vue'
import Loader from '../components/Loader_com.vue'
import Swal from 'sweetalert2'
import axios from 'axios'

export default {

	data() {
		return {
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			message: '',
			loading: false,
		}
	},

	components: {
		NaviBar,
		Loader,
	},

	mounted() {
		this.setMetaData();
		window.fbq('track', 'ViewContent');
	},

	methods: {

		setMetaData() {
            useHead({
                title: `CONTACT | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Get in touch with Octick for expert digital marketing and development solutions. Reach our head office in Kandy, Sri Lanka, or our Australian office in Perth. Call us at AU+61 452 447 899, US+1 (848) 350 2408, or SL+94 77 123 0087. Email us at info@octick.com for inquiries or to schedule a consultation.'
                    },
                ],
            });
        },

		async submit() {
			if (this.firstName != '' && this.lastName != '' && this.email != '' && this.mobile != '' && this.message != '') {
				try {
					this.loading = true;
					const api = 'https://api.octick.com/contact-us';
					await axios.post(api, {
						name: this.firstName + ' ' + this.lastName,
						email: this.email,
						mobile: this.mobile,
						message: this.message,
					}).then((response) => {
						this.loading = false;
						console.log(response.data)
						if (response.data == '201') {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: 'Your message has been submitted. We will contact you as soon as possible.',
							})
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Oops...!',
								text: 'Something went wrong. Please check Your Network and try again.',
							})
						}
					});
				}
				catch (e) {
					this.loading = false;
					Swal.fire({
						icon: 'error',
						title: 'Oops...!',
						text: 'Something went wrong. Please Check Your Network and try again.',
					})
				}
			} else {
				Swal.fire({
					icon: 'warning',
					title: 'Oops...!',
					text: 'All required input field must be filled.',
				})
			}
			this.firstName = '';
			this.lastName = '';
			this.email = '';
			this.mobile = '';
			this.message = '';
		}
	}

}
</script>

<style scoped>
.info {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.icon-box img {
	width: 25px;
	height: 25px;
}

.col-lg-6 {
	padding: 0;
}

p {
	font-weight: 500;
}

input,
textarea {
	margin-bottom: 20px;
}

.contact-us {
	min-height: 100vh;
	padding: 50px;
	background-image: url('../assets/banners/banner_op.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.contact-us-container {
	max-width: 800px;
}

.heading-mainn {
	margin-top: 30px;
	font-size: 40px;
	text-align: center;
}

.content-wrapper {
	margin: 20px 0;
	display: flex;
}

.address-type {
	font-size: 18px;
	margin-bottom: 5px;
}

a {
	color: black;
	text-decoration: none;
}

a .cnt-au {
	margin-right: 5px;
}

a .cnt-us {
	margin-right: 7px;
}

a .cnt-sl {
	margin-right: 11px;
}

.icon-box {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.right {
	width: 100%;
	padding: 20px;
	border-radius: 10px;
	background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 1200px) {

	.contact-us {
		padding: 30px;
	}

}

@media only screen and (max-width: 991px) {
	.info {
		margin-top: 30px;
		justify-content: flex-start;
	}
}

@media only screen and (max-width: 767px) {

	h4 {
		font-size: 20px;
	}

	.heading-mainn {
		font-size: 30px;
		text-align: center;
	}

	.contact-us {
		padding: 20px 10px;
	}

}
</style>