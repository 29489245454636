import { createApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head'
import VueGtag from 'vue-gtag'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './routes'
import './assets/css/main.css'

const head = createHead();

createApp(App)
.use(VueGtag, {
    appName: 'octick',
    pageTrackerScreenViewEnabled: true,
    config: {id: 'G-ZEJF9S268Y'}
})
.use(router)
.use(head)
.mount('#app')