<template>
    <div class="main-banner">
        <div class="container-one">
            <div class="heading-main-wrapper">
                <h1 class="heading-main-banner" id="main-banner-heading">
                    <slot name="header-1"></slot>
                </h1>
            </div>
            <div class="banner-container">
                <img class="banner-container-img" src="../assets/banners/main_banner_2_low-min.png" alt="">
            </div>
            <div class="content-container">
                <span class="sub-hedding-1">
                    <slot name="header-2"></slot>
                </span>
                <p>
                    <slot name="content-2"></slot>
                </p><br>
                <span class="sub-hedding-2">
                    <slot name="header-3"></slot>
                </span>
                <p>
                    <slot name="content-3"></slot>
                </p><br>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>


<style scoped>
img {
    max-width: 100%;
    max-height: 100%;
}

p {
    margin-top: 30px;
}

.heading-main-wrapper{
    display: flex;
    justify-content: center;
}

.heading-main-banner {
    margin-top: 30px;
    text-align: center;
    font-size: 55px;
    position: absolute;
    top: 10%;
    z-index: 1;
}

#main-banner-heading{
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-delay: .5s;
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.heading-sub {
    max-width: 1000px;
    text-align: center;
}

.container-one {
    padding-top: 50px;
    position: relative;
}

.banner-container {
    width: 100%;
    height: 80vh;
    position: relative;
}

.banner-container-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
    opacity: .5;
}

.content-container {
    width: 100%;
    padding: 0 50px;
    position: relative;
    bottom: 80px;
}

.sub-hedding-1 {
    font-size: 25px;
    font-weight: 500;
    background: linear-gradient(to right, #4527FE, #4527FE, #990ABD, #990ABD);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.sub-hedding-2 {
    font-size: 23px;
    font-weight: 500;
    background: linear-gradient(to right, #4527FE, #4527FE, #990ABD, #990ABD);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

@media only screen and (max-width: 1200px) {

    .content-container {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 991px) {

    .heading-main-banner {
        font-size: 40px;
    }

    .sub-hedding-1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 767px) {

    .banner-container {
        height: 70vh;
    }

    .heading-main-banner {
        font-size: 30px;
    }

    .content-container {
        padding: 0 10px;
        bottom: 30px;
    }

    .sub-hedding-1,
    .sub-hedding-2 {
        font-size: 20px;
    }
}
</style>