<template>
    <div>
        <div class="image"></div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

.image {
    width: 100%;
    height: 100%;
    position: absolute;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 991px) {
    .image {
        height: 70vh;
    }
}
</style>