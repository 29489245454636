<template>
    <div class="development">
        <NaviBar />
        <div class="container-1">
            <MainBanner>
                <template v-slot:header-1>Simplified Development.</template>
                <template v-slot:header-2>Our Software Development Range </template>
                <template v-slot:content-2>We're a digital software solutions agency creating next-level websites and
                    systems by strategically blending user experience into them. We combine innovative ideas and
                    solutions from our end to make your project the best it can be. We will be alongside your business
                    in the long run, innovating along the journey. No idea too small, No idea too big.</template>
                <template v-slot:header-3>Our Features</template>
                <template v-slot:content-3>
                    <ul>
                        <li>Our secure web coding is the industry norm and is just loved by search engines.</li>
                        <li>Our innovative concept works draw the eye and deliver higher rates of change.</li>
                        <li>Websites we build intuitively show diverse formats on different platforms.</li>
                        <li>Monthly inexpensive support bundles so we can maintain your online presence up to date and save
                            your time.</li>
                        <li>Eligible, high-volume traffic in our successful online marketing drives.</li>
                        <li>To satisfy the company's needs, we are versatile.</li>
                    </ul>
                </template>
            </MainBanner>
        </div>
        <div class="container-2">
            <div class="heading">
                <h3>Our Development Process</h3>
            </div>
            <div class="img-container">
                <img src="../assets/images/development/Development-process-min.png" alt="">
            </div>
        </div>
        <div class="container-3 container-xl">
            <div class="heading">
                <h3>Find the right Development process</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Startup Wiz</template>
                        <template v-slot:title-sub>Starting up on your first website for your business or idea? Are you
                            on a tight budget?</template>
                        <template v-slot:content>Now is the best time to start up your business idea. Testing your
                            concept before going to market is always a smart move. With our experience in the space
                            combined with the low-cost platforms such as Wix or Shopify abilities and low costs on
                            maintenance, this may be the best option for you.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/wix.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Startup Beast</template>
                        <template v-slot:title-sub>Setting up your website for your business or idea? or just want us
                            to redesign and optimize it for you?</template>
                        <template v-slot:content>WordPress contains plugin architecture and a template system, so you
                            can customize any website to fit your business, blog, portfolio, or online store. But
                            building it could be overwhelming and you might not optimize it for your benefit. Our
                            experienced developers in the space can help you build the best version of the website
                            catered to your business. Its abilities in being low costs on maintenance may be the best
                            option for you.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/wordpress-lg.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="e-commerce-websites">
                    <Card>
                        <template v-slot:title-main>E-commerce Websites</template>
                        <template v-slot:title-sub>Transform your business with a custom e-commerce website. We design, develop, and optimize online stores for your success.</template>
                        <template v-slot:content>In the rapidly evolving online shopping environment, maintaining an
                            e-commerce website is essential for customer retention and maximum success. While launching your
                            website is an important early step, it’s important to keep updating and maintaining it. Finding
                            time for these activities can be difficult in today’s increasingly complex online environment.
                            That’s where our company comes in, providing the most practical solutions to meet your
                            needs.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/vue.png" alt="">
                            <div style="width: 50px;"></div>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/wordpress-lg.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="custom-websites">
                    <Card>
                        <template v-slot:title-main>Custom Websites</template>
                        <template v-slot:title-sub>Create a custom coded website tailored just for you. Engagement -
                            lead generation focused.</template>
                        <template v-slot:content>Our custom web frameworks are not based on templates, extensions or
                            found code. We, give you a custom website that is personalized, functional, secure and
                            reliable which is tailored just for your business. Our secure web coding is the industry
                            norm and is just loved by search engines.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/vue.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/node.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Web-based Systems</template>
                        <template v-slot:title-sub>Setting up your systems in place to make processes simpler for you and
                            your customers safely on the cloud.</template>
                        <template v-slot:content>Our experience lies in the design and creation of Web-based systems to
                            serve the needs of the consumer and to enhance the businesses functionality overall. Our
                            years of experience in the world of web apps gives us the edge to figure out where youre
                            business needs innovation.</template>
                        <template v-slot:img>
                            <img style="width: 100px;" src="../assets/images/home/tech_logos/vue.png" alt="">
                            <img style="width: 150px;" src="../assets/images/home/tech_logos/node.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>POS Systems</template>
                        <template v-slot:title-sub>Creating a Point-of-Sale system tailored just for your business might be
                            just the thing to give you the edge on your business.</template>
                        <template v-slot:content>We are a complete supplier of retail applications and hardware systems
                            for information technology. Our continuing research and development in this area have many
                            advantages for our customers. Having the opportunity to buy our already completed product or
                            completely custom make one tailored for your business. You can decide for this be a desktop
                            application vs being a Cloud based application.</template>
                        <template v-slot:img>
                            <img style="width: 110px;" src="../assets/images/home/tech_logos/dotnet.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>Desktop Applications</template>
                        <template v-slot:title-sub>Tailor made Desktop applications for your business might be what you
                            needed.</template>
                        <template v-slot:content>In the development of user-friendly desktop systems and customer-server
                            software's suited to individual company needs, utilizing state-of-the-art technology to
                            build custom-desktop applications in your behalf. We have also built complicated scraping
                            software's for our customers lead generation and other needs.</template>
                        <template v-slot:img>
                            <img style="width: 120px;" src="../assets/images/home/tech_logos/java.png" alt="">
                            <img style="width: 110px;" src="../assets/images/home/tech_logos/dotnet.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="mobile-applications">
                    <Card>
                        <template v-slot:title-main>Mobile Applications</template>
                        <template v-slot:title-sub>Had an app idea that you know would be a hit? Want one planned and
                            developed for your business by professionals?</template>
                        <template v-slot:content>We allow brands to extend their presence and communicate better with
                            consumers around the world staring with simple apps as getting breakfast bookings to complex
                            data driven sales applications. Our years of experience in the development of Smartphone
                            applications pledge to give our consumers the latest and greatest out of
                            technology.</template>
                        <template v-slot:img>
                            <img style="width: 110px;" src="../assets/images/home/tech_logos/flutter-new.png" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="website-maintenance">
                    <Card>
                        <template v-slot:title-main>Website Maintenance</template>
                        <template v-slot:title-sub>Ensure your website stays secure, up-to-date, and performs flawlessly. Our website maintenance services keep your online presence worry-free.</template>
                        <template v-slot:content>Take advantage of this incredible opportunity to supercharge your sales
                            with customized website solutions! Our team of Web design experts will design user-friendly,
                            results-driven and up-to-date designs just for you.</template>
                        <template v-slot:img>
                            <img src="" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card" id="domain&hosting">
                    <Card>
                        <template v-slot:title-main>Domain & Hosting</template>
                        <template v-slot:title-sub>Secure your online identity with domain registration and enjoy reliable hosting services for a seamless online presence.</template>
                        <template v-slot:content>We offer high quality domain hosting services, ensuring your online
                            presence is secure and reliable. Whether you are launching a website or moving to an existing
                            new one, our team of experts is here to provide you with the perfect domain name and robust
                            hosting solution to meet your needs.</template>
                        <template v-slot:img>
                            <img src="" alt="">
                        </template>
                    </Card>
                </div>
                <div class="col-lg-6 col-md-6 card">
                    <Card>
                        <template v-slot:title-main>AI Chatbot integration</template>
                        <template v-slot:title-sub>Enhance user engagement and support with AI chatbot integration. Automate responses, provide instant assistance, and boost customer satisfaction effortlessly.</template>
                        <template v-slot:content>Free up your valuable contact center resources mundane and give your
                            customers and stakeholders a 24x7 communication channel to get the information they need.
                            Combining industry-leading speech understanding with advances in machine learning and AI, our
                            chatbot solutions aim to interact with your customers and stakeholders in a natural conversation
                            whenever they want.</template>
                        <template v-slot:img>
                            <img src="" alt="">
                        </template>
                    </Card>
                </div>
                <div class="h-line"></div>
                <div class="heading">
                    <h3>Other Services</h3>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 card">
                        <Card>
                            <template v-slot:title-main>Customer Service</template>
                            <template v-slot:title-sub>Dealing with your customers can be overwhelming at time’s. Training
                                representatives and hiring more as you grow has never been easier.</template>
                            <template v-slot:content>Customer Service Representative would handle complaints, provide
                                appropriate solutions for customers, and follow up to resolve any issues and enhance
                                customer s experience of the business. Hire as you get bigger.</template>
                            <template v-slot:img>
                                <img src="" alt="">
                            </template>
                        </Card>
                    </div>
                    <div class="col-lg-6 col-md-6 card" id="seo-content-writing">
                        <Card>
                            <template v-slot:title-main>SEO Content Writing</template>
                            <template v-slot:title-sub>Get your SEO content ready by letting our well experienced content
                                writers do there job.</template>
                            <template v-slot:content>The first two pages of Google search results will help your business
                                get measurable results which will drive your company forward. We work with your company to
                                understand and implement a comprehensive SEO strategy, driving your website with targeted
                                traffic that would improve your conversion revenues.</template>
                            <template v-slot:img>
                                <img src="../assets/images/home/tech_logos/sem-rush.webp" alt="">
                                <div style="width: 50px;"></div>
                                <img src="../assets/images/home/tech_logos/ahrefs.webp" alt="">
                            </template>
                        </Card>
                    </div>
                    <div class="col-lg-6 col-md-6 card">
                        <Card>
                            <template v-slot:title-main>Cyber Security</template>
                            <template v-slot:title-sub>Having extra precautions set as well as regularly checking up on your
                                security can be useful down the road to success. </template>
                            <template v-slot:content>Having that extra step in the right direction in the technology
                                industry would be best in the long run. Our industry experts are always available to give
                                your business the best practice of protecting systems, networks, and programs from digital
                                attacks. Our teams of experts will get your systems checked fixed and ready to be published
                                hassle free.</template>
                            <template v-slot:img>
                                <img src="" alt="">
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-4">
            <HowWeCanHelp />
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import NaviBar from '../components/navigation_com.vue'
import MainBanner from '../components/main-banner-dev.vue';
import Card from '../components/card-dev.vue';
import HowWeCanHelp from '../components/HowWeCanHelp.vue';

export default {

    components: { MainBanner, Card, HowWeCanHelp, NaviBar },

    mounted() {
        this.setMetaData();
        window.fbq('track', 'ViewContent');
    },

    methods: {
        setMetaData() {
            useHead({
                title: `DEVELOPMENT | OCTICK`,
                meta: [
                    {
                        name: 'description',
                        content: 'Discover Octick\'s Simplified Development services. From startup websites to custom-coded solutions, e-commerce platforms, mobile apps, and more, we offer secure, innovative digital solutions tailored to your needs. Enhance your online presence with our expert web development and maintenance services. Contact us today.'
                    },
                ],
            });
        },
    }

}

</script>

<style scoped>
img {
    max-width: 100%;
    max-height: 100%;
}

.container-2 {
    padding: 0 10px;
}

.heading h3 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 50px;
    text-align: center;
    background: linear-gradient(to right, rgb(98, 0, 255), rgb(98, 0, 255), rgb(0, 140, 255), rgb(0, 140, 255));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.img-container {
    max-width: 1000px;
    max-height: 1000px;
    margin: 0 auto;
}

.container-3 {
    margin-top: 100px;
    padding: 0 50px;
}

.card {
    margin: 10px 0;
    border: none;
}

@media only screen and (max-width: 1200px) {

    .container-3 {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 991px) {

    .heading h3 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 767px) {

    .colo-lg-6,
    .col-md-6,
    .row {
        padding: 0;
        margin: 0;
    }

    .container-3 {
        padding: 0 10px;
    }

    .heading h3 {
        font-size: 20px;
    }
}</style>