<template>
  <!-- <NaviBar/> -->
  <router-view/>
  <Foo/>
</template>

<script>
import Foo from './components/Footer_com.vue'
// import NaviBar from './components/navigation_com.vue'

export default {

    components: {
        Foo,
        // NaviBar,
    },

}
</script>

<style>

</style>